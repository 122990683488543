import service from "@/api/service";
import {
  SearchRequest,
  ApproveRequest,
  DeleteRequest
} from "@/api/booking/request";
import { SearchResponse } from "@/api/booking/response";
import { CommonResponse } from "@/api/response";

/**
 * 予約確認情報検索APIをコールします。
 *
 * @param searchRequest 予約確認情報検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-booking", searchRequest);
  return response.data as SearchResponse;
}

/**
 * 予約情報承認APIをコールします。
 *
 * @param approveRequest 予約情報承認のリクエストボディ
 * @return ApproveRequest
 */
export async function approve(approveRequest: ApproveRequest) {
  const response = await service.post("/approve-booking", approveRequest);
  return response.data as CommonResponse;
}

/**
 * 予約情報承認APIをコールします。
 *
 * @param deleteRequest 予約情報承認のリクエストボディ
 * @return DeleteRequest
 */
export async function deleteOne(deleteRequest: DeleteRequest) {
  const response = await service.post("/delete-booking", deleteRequest);
  return response.data as CommonResponse;
}
