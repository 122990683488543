import { Component, Prop, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import UlDialogDelete from "@/components/UlDialogDelete.vue";
import UlDialogApprove from "@/components/UlDialogApprove.vue";
import { DEFAULT_TABLE_OPTIONS, TableOptions } from "@/api/request";
import BookingSearch, { ListItem } from "@/store/booking/search";
import BookingApprove from "@/store/booking/approve";
import BookingDelete from "@/store/booking/delete";
import {
  SearchRequest,
  ApproveRequest,
  DeleteRequest
} from "@/api/booking/request";
import Flash, { SuccessAlert, ErrorAlert } from "@/store/common/flash";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIDataTable,
    UIDatePicker,
    UlDialogDelete,
    UlDialogApprove
  }
})
export default class Confirm extends Vue {
  @Prop({ type: String, required: true })
  shopId!: string;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "予約";
  headingSub = "Booking";
  breadCrumbs = [
    { text: "予約", disabled: true },
    { text: "店舗一覧", disabled: false, to: { name: "booking" } },
    { text: "確認", disabled: true }
  ];
  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "予約受付日時", value: "createDate" },
    { text: "予約者", value: "subscriber", sortable: false },
    { text: "予約希望日時", value: "date" },
    { text: "予約人数", value: "number", sortable: false },
    { text: "ご予約内容", value: "menuName", sortable: false },
    { text: "ご要望", value: "comment", sortable: false },
    { text: "予約確定日時", value: "custom" }
  ];

  // 承認ステータス
  statuses = [
    { text: "承認", value: 1 },
    //NEW_DEV-1571 cyber start
    { text: "未承認", value: 0 }
    //NEW_DEV-1571 cyber end
  ];

  // ------------
  // 変動値
  // ------------

  // 削除ダイアログの表示有無
  showDeleteDialog = false;

  // 削除される予約者名
  deletingBookingName = null as string | null;

  // 削除される予約ID
  deletingBookingId = null as number | null;

  // 承認ダイアログの表示有無
  showApproveDialog = false;

  // 承認される予約者名
  approvingBookingName = null as string | null;

  // 承認される予約ID
  approvingBookingId = null as number | null;

  // ローディングステータス
  isLoading = false;

  // 検索入力オプション
  inputOptions = {
    id: Number(this.shopId),
    status: null as number | null,
    start: null as string | null,
    end: null as string | null,
    name: null as string | null
  };

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = DEFAULT_TABLE_OPTIONS;

  // ------------

  /**
   * テーブルに表示するアイテムリスト（VDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return BookingSearch.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get totalCount() {
    return BookingSearch.getTotalCount;
  }

  get shopName() {
    return this.$route.params.shopName || "";
  }

  /**
   * createdライフサイクルフック（VDataTableコンポーネントに渡す）
   */
  async created() {
    await BookingSearch.restore(this.inputOptions as SearchRequest);
    const request = BookingSearch.getSearchRequest;
    // 検索入力オプション
    request.status && (this.inputOptions.status = request.status);
    request.start && (this.inputOptions.start = request.start);

    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
    await this.search();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await BookingSearch.clearResponse();
    await BookingApprove.clearResponse();
    await BookingDelete.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    await Flash.clear();
    await this.search();
  }

  /**
   * 予約確定開始日のDatePickerがクリックされた際のコールバック
   *
   * @param date 予約確定開始日
   */
  changeStartCallback(date: string | null) {
    this.inputOptions.start = date;
  }

  /**
   * 予約確定終了日のDatePickerがクリックされた際のコールバック
   *
   * @param date 予約確定終了日
   */
  changeEndCallback(date: string | null) {
    this.inputOptions.end = date;
  }

  /**
   * テーブル行のアクションボタンがクリックされた際のコールバック
   *
   * @param item 選択行のBookingItem
   */
  actionClickCallback(item: { id: number; name: string }) {
    this.showApproveDialog = true;
    this.approvingBookingId = item.id;
    this.approvingBookingName = `${item.name}様の予約`;
  }

  /**
   * テーブル行の編集ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のBookingItem
   */
  editClickCallback(item: ListItem) {
    this.$router.push({
      name: "booking-confirm-edit",
      params: { shopId: this.shopId, id: String(item.id), shopName: this.$route.params.shopName }
    });
  }

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のBookingItem
   */
  deleteClickCallback(item: ListItem) {
    this.showDeleteDialog = true;
    this.deletingBookingId = Number(item.id);
    this.deletingBookingName = `${item.name}様の予約`;
  }

  /**
   * ダイアログの削除のコールバック
   */
  async doDeleteOnDialog() {
    await Flash.clear();
    this.showDeleteDialog = false;
    if (!this.deletingBookingId) {
      return;
    }

    this.isLoading = true;
    await BookingDelete.deleteOne({
      id: this.deletingBookingId
    } as DeleteRequest);
    if (BookingDelete.isSuccess) {
      await Flash.setSuccessNow({
        message: "予約を削除しました。",
        consumePath: ""
      } as SuccessAlert);
      await this.search();
    } else {
      await Flash.setErrorNow({
        message: BookingDelete.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  async doApproveOnDialog() {
    await Flash.clear();
    this.showApproveDialog = false;
    if (!this.approvingBookingId) {
      return;
    }

    this.isLoading = true;
    await BookingApprove.approve({
      id: this.approvingBookingId
    } as ApproveRequest);
    if (BookingApprove.isSuccess) {
      await Flash.setSuccessNow({
        message: "予約を承認しました。",
        consumePath: ""
      } as SuccessAlert);
      await this.search();
    } else {
      await Flash.setErrorNow({
        message: BookingApprove.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * 検索処理
   */
  async search() {
    this.isLoading = true;
    let request = this.createRequest();
    await BookingSearch.search(request);
    if (!BookingSearch.isSuccess) {
      await Flash.setErrorNow({
        message: BookingSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }

    this.isLoading = false;
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest(): SearchRequest {
    const request = this.tableOptions as SearchRequest;
    request.id = this.inputOptions.id;
    //NEW_DEV-1571 cyber start
    if (this.inputOptions.status != null) {
    //NEW_DEV-1571 cyber end
      request.status = this.inputOptions.status;
    } else {
      delete request.status;
    }

    if (this.inputOptions.start) {
      request.start = this.inputOptions.start;
    } else {
      delete request.start;
    }

    if (this.inputOptions.end) {
      request.end = this.inputOptions.end;
    } else {
      delete request.end;
    }

    if (this.inputOptions.name) {
      request.name = this.inputOptions.name;
    } else {
      delete request.name;
    }
    if (request.sortKey == 'custom') {
      request.sortKey = 'booking_date';
    } else if (!request.sortKey || request.sortKey == 'id') {
      request.sortKey = 'create_date';
    }

    return request;
  }
}
