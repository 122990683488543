import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { ApproveRequest } from "@/api/booking/request";
import * as BookingAPI from "@/api/booking";
import { CommonResponse, isSuccess } from "@/api/response";

const MODULE_NAME = "booking/approve";

/**
 * 予約情報承認API（/approve-booking）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Approve extends VuexModule {
  // state
  commonResponse: CommonResponse = {} as CommonResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.commonResponse);
  }

  get getMessage() {
    return this.commonResponse.message;
  }

  // MutationActions
  @MutationAction
  async approve(approveRequest: ApproveRequest) {
    const commonResponse = await BookingAPI.approve(approveRequest);
    return {
      commonResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      commonResponse: {} as CommonResponse
    };
  }
}

export default getModule(Approve);
