import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { SessionStorage } from "@/store/session-storage";
import { SearchResponse, SearchItem } from "@/api/booking/response";
import { SearchRequest } from "@/api/booking/request";
import * as BookingAPI from "@/api/booking";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "booking/search";

/**
 * 予約一覧のデコレーター
 */
export class ListItem {
  constructor(private _item: SearchItem) {}
  get id() {
    return this._item.id;
  }
  get createDate() {
    return ListItem.formatHyphenToSlash(this._item.createDate);
  }
  get name() {
    return this._item.name;
  }
  get date() {
    return ListItem.formatHyphenToSlash(
      `${this._item.date} ${this._item.time}`
    );
  }
  get number() {
    if (this._item.number != null) {
      return `${this._item.number}人`;
    }
    return null;
  }
  get menuName() {
    return this._item.menuName;
  }
  get comment() {
    return this._item.comment;
  }
  get bookingDate() {
    if (this._item.bookingDate) {
      return ListItem.formatHyphenToSlash(this._item.bookingDate);
    }
    return "";
  }
  get subscriber() {
    return `お名前：
    ${this._item.name}
    メールアドレス：
    ${this._item.email}
    電話番号：
    ${this._item.phone}`;
  }
  get actionFlg() {
    return this._item.actionFlg;
  }
  get writeFlg() {
    return this._item.writeFlg;
  }
  get deleteFlg() {
    return this._item.deleteFlg;
  }
  private static formatHyphenToSlash(orig: string) {
    return orig.replace(/-/g, "/");
  }
}

/**
 * 予約店舗一覧・検索API（/search-booking-shop）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Search extends VuexModule {
  // state
  searchRequest: SearchRequest = {} as SearchRequest;
  searchResponse: SearchResponse = {} as SearchResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.searchResponse);
  }

  get getMessage() {
    return this.searchResponse.message;
  }

  get getItems() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.items.map(i => new ListItem(i)) || [];
    } else {
      return [];
    }
  }

  get getTotalCount() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.totalCount || 0;
    } else {
      return 0;
    }
  }

  get getSearchRequest() {
    return this.searchRequest;
  }

  // MutationActions
  @MutationAction
  async search(searchRequest: SearchRequest) {
    const searchResponse = await BookingAPI.search(searchRequest);
    SessionStorage.setObject(MODULE_NAME, searchRequest);
    return {
      searchRequest,
      searchResponse
    };
  }

  @MutationAction
  async restore(initial = {} as SearchRequest) {
    const searchRequest = SessionStorage.getObject(
      MODULE_NAME,
      initial
    ) as SearchRequest;
    return {
      searchRequest
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      searchResponse: {} as SearchResponse
    };
  }
}

export default getModule(Search);
